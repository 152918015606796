jQuery(function($) {

    ////////////////////////////////////////
    //// FVスライドアニメーション
    ////////////////////////////////////////
    // $(window).on('load', function() {
        
        let activeSlideIndex = 0,
        slides = $('#ta-slider .slide'),
        lastSlideIndex = parseInt(slides.length-1),
        duration = 4000,
        activeSlide,
        progBar = $('.mv-progress-bar-line'),
        places = $('.progress-inc'),
        activePlace,
        nums = $('.progress-num'),
        activeNum;

        // スライド時の動き（execSlideによってコールバックされる）
        let changePage = function() {

            activeSlide = slides.eq(activeSlideIndex);
            activePlace = places.eq(activeSlideIndex);
            activeNum = nums.eq(activeSlideIndex);

            slides.removeClass('active');
            activeSlide.addClass('active');

            places.removeClass('active');
            activePlace.addClass('active');

            nums.removeClass('active');
            activeNum.addClass('active');

            // プログレスバー2回目以降
            progBar.animate({
                'height': '100%'
            }, duration, function() {
                progBar.css('height', '0');
            });

        }

        // スライド実行関数
        function execSlide(action) {

            activeSlide = slides.eq(activeSlideIndex);
            activePlace = places.eq(activeSlideIndex);
            activeNum = nums.eq(activeSlideIndex);

            // 一枚目のスライドを表示, プレイス, ナンバーを初期化
            activeSlide.addClass('active');
            activePlace.addClass('active');
            activeNum.addClass('active');

            // プログレスバー一回目
            progBar.animate({
                'height': '100%'
            }, duration, function() {
                progBar.css('height', '0');
            });

            // 自動再生
            autoSlide = setInterval(function() {

                if( activeSlideIndex === lastSlideIndex ) {

                    activeSlideIndex = 0;
                    action();

                } else {

                    activeSlideIndex ++;
                    action();

                }

            }, duration);
        }



        //スライド開始
        if(slides.length) {

            // ウィンドウにフォーカスを設定してsetinterval実行
            $(window).focus();
            execSlide(changePage);

            // ウィンドウからフォーカスがはずれたら各setintervalをクリア、ウィンドウにフォーカスしたら再度setinterval開始
            $(window).bind("focus",function(){

                    clearInterval(autoSlide);
                    execSlide(changePage);

                }).bind("blur",function(){

                    clearInterval(autoSlide);

                });
                
        }

    // });//window load function

    // スワイパー初期化（事例紹介セクション）
    var swiper = new Swiper('.swiper-container',{
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        loop: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        speed: 1000,
        centeredSlides : true,
        slideActiveClass: 'swiper-slide-active',
    });

    // 事例紹介テキスト変更の変数
    let caseNum = $('.case-slide-num .num'),
        caseName = $('.case-desc-top .case-name'),
        caseTitle = $('.case-desc-title .case-title'),
        caseTags = $('.case-desc-tag .case-tags'),
        caseDesc = $('.case-desc-area');

    //事例紹介テキスト初期化
    let initTxt = function() {
        caseNum.eq(0).addClass('active');
        // caseName.eq(0).addClass('active');
        // caseTitle.eq(0).addClass('active');
        // caseTags.eq(0).addClass('active');
        caseDesc.eq(0).addClass('active');
    }
    initTxt();

    //事例紹介テキスト変更実行
    swiper.on('slideChange', function() {
        console.log('realIndex: ' + swiper.realIndex);

        caseNum.removeClass('active');
        caseNum.eq(swiper.realIndex).addClass('active');

        caseDesc.removeClass('active');
        caseDesc.eq(swiper.realIndex).addClass('active');

    });

    // 採用事例イメージ幅変化
    let windowWidth = window.innerWidth;
	let windowTab = 960;
	if (windowWidth > windowTab) {

        $('.js-scroll-width').each(function() {

            let winH = $(window).height(),
                recImage = $(this),
                thisPos = recImage.offset().top,
                current = 75,
                scroll;
    
                $(window).on('scroll', function() {
    
                    scroll = $(window).scrollTop();
                    current = ( (1 - (thisPos - scroll) / winH)) * 25;
    
                    if (current > 99.99) {
                        current = 100;
                    }
    
                    let currentPlus = current + 75;
                    if (currentPlus > 99.99) {
                        currentPlus = 100;
                    }
    
                    if(scroll > thisPos - winH) {
                        recImage.css({width: currentPlus + '%'});
                    }
                });
    
        });
        
    }

    // パララックス
    $('.para-content').each(function() {

        let winH = $(window).height(),
            para = $(this),
            paraPos = para.offset().top,
            Num = 0,
            scroll;

            $(window).on('scroll', function() {

                scroll = $(window).scrollTop();
                Num = ( (1 - (paraPos - scroll) / winH)) * 30;
                
                if (Num > 199.9999) {
                    Num = 200;
                }
                // console.log(Num);
                if(scroll > paraPos - winH) {

                    para.css('top', Num - 40 + '%');

                }
            });

    });

    // オリジナルanimate関数
    // $.fn.animate2 = function (properties, duration, ease) {
    //     ease = ease || 'ease';
    //     var $this = this;
    //     var cssOrig = { transition: $this.css('transition') };
    //     return $this.queue(next => {
    //         properties['transition'] = 'all ' + duration + 'ms ' + ease;
    //         $this.css(properties);
    //         setTimeout(function () {
    //             $this.css(cssOrig);
    //             next();
    //         }, duration);
    //     });
    // };

     // スワイパー初期化（チーム紹介スマホ版）
     var teamSwiper = new Swiper('.team-swiper-container',{
        pagination: {
            el: '.team-swiper-pagination',
            type: 'bullets',
        },
        loop: false,
        spaceBetween: 0,
        slidesPerView: 1,
        slideActiveClass: 'swiper-slide-active',
    });
    
});